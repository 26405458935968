const num_allowed =
[
	' ', '.', ',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
];

const neg_allowed = num_allowed.concat(['-']);

export default function safeNumber (value, params={ })
{
	const allowed = params['allow-negative'] ? neg_allowed : num_allowed;

	return Array.from(value)
	.filter(char => allowed.includes(char))
	.join('');
}