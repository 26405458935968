import FormsStore from '../../Stores/Forms'

export default function resetForm (name='no-form', cb, hook)
{
	FormsStore.update(function (state)
	{
		state[name] = { };

		if (typeof hook === 'function') hook(state[name]);

		if (typeof cb === 'function') cb();
	});
}