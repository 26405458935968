import { lazy, Suspense } from 'react'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { HelmetProvider } from 'react-helmet-async'

import { PrimeReactProvider } from 'primereact/api'

import FrontLayout from './Components/Layouts/FrontLayout'
import AuthLayout  from './Components/Layouts/AuthLayout'
import BackLayout  from './Components/Layouts/BackLayout'

import NotFoundPage from './Components/Errors/Pages/NotFoundPage'
import RouterError  from './Components/Errors/RouterError'

import FrontVideoCourseLoader from './Components/Loaders/FrontVideoCourseLoader'
import FrontPdfCourseLoader   from './Components/Loaders/FrontPdfCourseLoader'
import ContactFormLoader      from './Components/Loaders/ContactFormLoader'
import DisciplineLoader       from './Components/Loaders/DisciplineLoader'
import FrontDiscLoader        from './Components/Loaders/FrontDiscLoader'
import PayOfferLoader         from './Components/Loaders/PayOfferLoader'
import SpeciesLoader          from './Components/Loaders/SpeciesLoader'
import LayoutLoader           from './Components/Loaders/LayoutLoader'
import CourseLoader           from './Components/Loaders/CourseLoader'
import MethodLoader           from './Components/Loaders/MethodLoader'
import AreaLoader             from './Components/Loaders/AreaLoader'
import OfferLoader            from './Components/Loaders/OfferLoader'

import RootSpinner from './Components/Spinners/RootSpinner'

import AppVersion from './Components/Configs/AppVersion'

import ROUTES from './routes'

const prime_params = { ripple: true, unstyled: true };

// Déclenché à la modif de l'history du navigateur, par ex ouverture/fermeture
// de modale
const shouldRevalidate = ({ currentUrl, nextUrl }) =>
{
	return currentUrl.pathname !== nextUrl.pathname;
};

const routes =
[
	{
		path: '*',
		errorElement: RouterError,
		element: <NotFoundPage error="Cette page n'existe pas." center />
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		shouldRevalidate,
		element: <FrontLayout />,

		children:
		[
			{
				path: '',
				lazy: ROUTES.HomePage
			},
			{
				path: 'qui-sommes-nous',
				lazy: ROUTES.AboutUsPage
			},
			{
				path: 'formations-theoriques',
				lazy: ROUTES.PDFCoursesPage
			},
			{
				path: 'formations-theoriques/:discipline',
				lazy: ROUTES.InnerDisciplinePage,
				loader: FrontDiscLoader
			},
			{
				path: 'formations-theoriques/:discipline/:course',
				lazy: ROUTES.PDFCoursePage,
				loader: FrontPdfCourseLoader
			},
			{
				path: 'formations-pratiques',
				lazy: ROUTES.VideoCoursesPage
			},
			{
				path: 'formations-pratiques/:course',
				lazy: ROUTES.VideoCoursePage,
				loader: FrontVideoCourseLoader
			},
			{
				path: 'contact',
				lazy: ROUTES.ContactPage
			},
			{
				path: 'offre-OAA',
				lazy: ROUTES.OfferPage
			},
			{
				path: 'offre-OAA/:code',
				lazy: ROUTES.PayOfferPage,
				loader: PayOfferLoader
			},
			{
				path: 'mes-commandes',
				lazy: ROUTES.MyOrdersPage
			},
			{
				path: 'mon-compte',
				lazy: ROUTES.MyAccountPage
			}
		]
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		shouldRevalidate,
		element: <AuthLayout />,

		children:
		[
			{
				path: 'login',
				lazy: ROUTES.LoginPage
			},
			{
				path: 'mot-de-passe-oublie',
				lazy: ROUTES.RequestPasswordPage
			},
			{
				path: 'mot-de-passe-oublie/nouveau-mot-de-passe/:token',
				lazy: ROUTES.NewPasswordPage
			}
		]
	},

	// {
	// 	path: 'mon-compte',
	// 	errorElement: RouterError,
	// 	loader: LayoutLoader,
	// 	shouldRevalidate,
	// 	element: <BackLayout />,

	// 	children:
	// 	[
	// 		{
	// 			path: '',
	// 			lazy: ROUTES.MyAccountPage
	// 		}
	// 	]
	// },

	{
		path: '/admin',
		errorElement: RouterError,
		loader: LayoutLoader,
		shouldRevalidate,
		element: <BackLayout />,

		children:
		[
			{
				path: 'accueil',
				lazy: ROUTES.AdminHomePage
			},
			{
				path: 'liste-des-utilisateurs',
				lazy: ROUTES.UsersListPage
			},
			{
				path: 'liste-des-utilisateurs/nouvel-utilisateur',
				lazy: ROUTES.NewUserPage
			},
			{
				path: 'liste-des-utilisateurs/:user_id',
				lazy: ROUTES.UserPage
			},
			{
				path: 'cdn-upload',
				lazy: ROUTES.CDNUploadPage
			}
		]
	},

	{
		path: '/gestion',
		errorElement: RouterError,
		loader: LayoutLoader,
		shouldRevalidate,
		element: <BackLayout />,

		children:
		[
			{
				path: 'disciplines',
				lazy: ROUTES.DisciplinesListPage
			},
			{
				path: 'disciplines/nouvelle',
				lazy: ROUTES.NewDisciplinePage
			},
			{
				path: 'disciplines/:id',
				lazy: ROUTES.UpdateDisciplinePage,
				loader: DisciplineLoader,
				shouldRevalidate
			},
			{
				path: 'especes',
				lazy: ROUTES.SpeciesListPage
			},
			{
				path: 'especes/nouvelle',
				lazy: ROUTES.NewSpeciesPage
			},
			{
				path: 'especes/:id',
				lazy: ROUTES.UpdateSpeciesPage,
				loader: SpeciesLoader,
				shouldRevalidate
			},
			{
				path: 'methodes',
				lazy: ROUTES.MethodsListPage
			},
			{
				path: 'methodes/nouvelle',
				lazy: ROUTES.NewMethodPage
			},
			{
				path: 'methodes/:id',
				lazy: ROUTES.UpdateMethodPage,
				loader: MethodLoader,
				shouldRevalidate
			},
			{
				path: 'zones',
				lazy: ROUTES.AreasListPage
			},
			{
				path: 'zones/nouvelle',
				lazy: ROUTES.NewAreaPage
			},
			{
				path: 'zones/:id',
				lazy: ROUTES.UpdateAreaPage,
				loader: AreaLoader,
				shouldRevalidate
			},
			{
				path: 'formations',
				lazy: ROUTES.CoursesListPage
			},
			{
				path: 'formations/nouvelle',
				lazy: ROUTES.NewCoursePage
			},
			{
				path: 'formations/:id',
				lazy: ROUTES.UpdateCoursePage,
				loader: CourseLoader,
				shouldRevalidate
			},
			{
				path: 'demandes-de-contact',
				lazy: ROUTES.ContactFormsListPage
			},
			{
				path: 'demandes-de-contact/:id',
				lazy: ROUTES.ContactFormPage,
				loader: ContactFormLoader
			},
			{
				path: 'liste-des-commandes',
				lazy: ROUTES.OrdersListPage
			},
			{
				path: 'offres',
				lazy: ROUTES.OffersListPage
			},
			{
				path: 'offres/:id',
				lazy: ROUTES.EditOfferPage,
				loader: OfferLoader
			}
		]
	}
];

const router = createBrowserRouter(routes);

export default function App ()
{
	return (
		<PrimeReactProvider value={ prime_params }>

			<HelmetProvider>

				<Suspense fallback={ <RootSpinner active /> }>
					<RouterProvider router={ router } />
				</Suspense>

				<AppVersion />

			</HelmetProvider>

		</PrimeReactProvider>
	);
}