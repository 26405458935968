import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function FrontVideoCourseLoader ({ params })
{
	return defer({ page: getPageData(true, params), params });
}

async function getPageData (retry=true, params)
{
	try
	{
		var { related, course } = await request('/front/courses/' + params.course)
		.config({ })
		.get({ type: 'video' });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE === 'NOT_FOUND')
		{
			throw { code: 404, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		related = await getPageData(false, params);
	}

	return { related, course: course };
}