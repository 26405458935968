import { Link, useAsyncError } from 'react-router-dom'

import { BsExclamationLg } from 'react-icons/bs'

import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'

import UnavailablePage from './Pages/UnavailablePage'
import NotAllowedPage  from './Pages/NotAllowedPage'
import NotFoundPage    from './Pages/NotFoundPage'

import useMyHome from '../../Hooks/useMyHome'

import Message from '../Pure/Message'

import bemit from '../Functions/bemit'

export default function LayoutError ()
{
	const error = useAsyncError();

	const my_home = useMyHome();

	if (error?.code === 401)
	{
		return <NotAllowedPage error={ error?.message } />;
	}
	else if (error?.code === 404)
	{
		return <NotFoundPage error={ error?.message } />;
	}
	else if (error?.code === 503)
	{
		return <UnavailablePage error={ error?.message } />;
	}
	else
	{
		console.error(error);
	}

	return (
		<main id="main" role="main"
			className="page-content page-content--error">

			<div className={ bemit('c-tile', ['bg-white', 'red']) }>

				<div className="c-tile__icon-container">
					<BsExclamationLg className="c-tile__icon"
						data-icon="BsExclamationLg"
					/>
				</div>

				<h2 className="c-tile__title">
					Erreur
					<span className="c-tile__sep" />
				</h2>

				<div className="c-tile__content">
					<Message color="green">
						Une erreur imprévue est survenue
					</Message>

					<div className="u-flex u-content-center u-margin-top-20">
						<Link to={ my_home }
							className={ bemit('c-flat-btn', ['icon-left']) }>

							<HiOutlineArrowNarrowLeft className="c-flat-btn__icon" />

							<span className="c-btn__label">
								Revenir à l'accueil
							</span>
						</Link>
					</div>

				</div>

			</div>

		</main>
	);
}