import { useCallback, useEffect, useRef, useState } from 'react'

import CrossIcon from '../Icons/Cross'

import PanelRegistrationForm from './PanelComponents/PanelRegistrationForm'
import PanelLoginForm        from './PanelComponents/PanelLoginForm'
import PanelWelcome          from './PanelComponents/PanelWelcome'

import { useLocation } from 'react-router-dom'

import RolesStore from '../../Stores/Roles'

import UserPanelStore from '../../Stores/UserPanelStore'

import { closeUserPanel } from '../../StoresFunctions/userPanelFunctions'

const components =
{
	Registration: PanelRegistrationForm,
	Login: PanelLoginForm,
	Welcome: PanelWelcome
};

export default function UserPanel (props)
{
	const { pathname } = useLocation();

	const _ref_mounted = useRef();

	const prevent_redirect = UserPanelStore.useState(s => s.redirect === false);
	const close_on_login = UserPanelStore.useState(s => s.close_on_login === true);

	const my_roles = RolesStore.useState(s => s.roles || []);

	const [active_component, setActiveComponent] = useState('Login');

	const Component = components[active_component];

	useEffect(function ()
	{
		if (!pathname) return;

		if (!_ref_mounted.current)
		{
			_ref_mounted.current = true;

			return;
		}

		closeUserPanel();
	},
	[pathname]);

	useEffect(function ()
	{
		if (my_roles.length)
		{
			setActiveComponent('Welcome');
		}
		else
		{
			setActiveComponent('Login');
		}
	},
	[my_roles]);

	const handleLogin = useCallback(function (response)
	{
		if (close_on_login)
		{
			closeUserPanel();
		}
	},
	[close_on_login]);

	return (
		<div className="c-user-panel">

			<div className="c-user-panel__panel">
				<button type="button" onClick={ closeUserPanel }
					className="c-user-panel__toggle-btn">
					<CrossIcon className="c-user-panel__toggle-icon-svg" />
					<span className="c-user-panel__toggle-label">
						Fermer
					</span>
				</button>

				<Component onLogin={ handleLogin }
					onRegister={ e => setActiveComponent('Registration') }
					no-redirect={ prevent_redirect }
				/>
			</div>

			<button className="c-user-panel__bg" type="button"
				onClick={ closeUserPanel }
			/>

		</div>
	);
}