import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function ContactFormLoader ({ params })
{
	return defer({ contact_form: getContactForm(true, params), params });
}

async function getContactForm (retry=true, params)
{
	try
	{
		var { data=[] } = await request('/contact-forms/get')
		.post({ id: params.id || '-1' });

		var contact_form = data?.[0];
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		contact_form = await getContactForm(false, params);
	}

	return contact_form;
}