const DeletedPage = () => 'Veuillez recharger la page.';

export const HomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/HomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AdminHomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/accueil/AdminHomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const CDNUploadPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/cdn-upload/CDNUploadPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UsersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/UsersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewUserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/nouvel-utilisateur/NewUserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/[user_id]/UserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ContactPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/contact/ContactPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const VideoCoursesPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/formations-pratiques/VideoCoursesPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const VideoCoursePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/formations-pratiques/[course]/VideoCoursePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const PDFCoursesPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/formations-theoriques/PDFCoursesPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const InnerDisciplinePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/formations-theoriques/[discipline]/InnerDisciplinePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const PDFCoursePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/formations-theoriques/[discipline]/[course]/PDFCoursePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ContactFormsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/demandes-de-contact/ContactFormsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ContactFormPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/demandes-de-contact/[id]/ContactFormPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const DisciplinesListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/disciplines/DisciplinesListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewDisciplinePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/disciplines/nouvelle/NewDisciplinePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateDisciplinePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/disciplines/[id]/UpdateDisciplinePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const SpeciesListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/especes/SpeciesListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewSpeciesPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/especes/nouvelle/NewSpeciesPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateSpeciesPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/especes/[id]/UpdateSpeciesPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const CoursesListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/formations/CoursesListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewCoursePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/formations/nouvelle/NewCoursePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateCoursePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/formations/[id]/UpdateCoursePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const OrdersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-commandes/OrdersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MethodsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/methodes/MethodsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewMethodPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/methodes/nouvelle/NewMethodPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateMethodPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/methodes/[id]/UpdateMethodPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const OffersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/offres/OffersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const EditOfferPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/offres/[code]/EditOfferPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AreasListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/zones/AreasListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewAreaPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/zones/nouvelle/NewAreaPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateAreaPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/zones/[id]/UpdateAreaPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const LoginPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/login/LoginPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyOrdersPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mes-commandes/MyOrdersPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyAccountPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mon-compte/MyAccountPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const RequestPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/RequestPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/nouveau-mot-de-passe/[token]/NewPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const OfferPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/offre-OAA/OfferPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const PayOfferPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/offre-OAA/[code]/PayOfferPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AboutUsPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/qui-sommes-nous/AboutUsPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

const ROUTES = { HomePage, AdminHomePage, CDNUploadPage, UsersListPage, NewUserPage, UserPage, ContactPage, VideoCoursesPage, VideoCoursePage, PDFCoursesPage, InnerDisciplinePage, PDFCoursePage, ContactFormsListPage, ContactFormPage, DisciplinesListPage, NewDisciplinePage, UpdateDisciplinePage, SpeciesListPage, NewSpeciesPage, UpdateSpeciesPage, CoursesListPage, NewCoursePage, UpdateCoursePage, OrdersListPage, MethodsListPage, NewMethodPage, UpdateMethodPage, OffersListPage, EditOfferPage, AreasListPage, NewAreaPage, UpdateAreaPage, LoginPage, MyOrdersPage, MyAccountPage, RequestPasswordPage, NewPasswordPage, OfferPage, PayOfferPage, AboutUsPage };
export default ROUTES;