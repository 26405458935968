import request from '../Components/Functions/ajax/request'

import { isTrue } from '../../shared/utilities'

import ContentStore from '../Stores/ContentStore'

export default async function fetchAccessConfig ({ onError, controller })
{
	try
	{
		var { pdf, videos } = await request('/auth/get-access-config')
		.config({ controller })
		.post();
	}
	catch (update)
	{
		pdf = false;
		videos = false;

		if (typeof onError === 'function') onError(update);
	}

	ContentStore.update(function (store)
	{
		store.pdf = isTrue(pdf);
		store.videos = isTrue(videos);
	});
}