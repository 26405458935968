import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { someTime } from '../../../shared/utilities'

export default function SpeciesLoader ({ params })
{
	return defer({ species: getSpecies(true, params), params });
}

async function getSpecies (retry=true, params)
{
	resetForm('Species');

	try
	{
		var { data=[] } = await request('/species/get')
		.post({ id: params.id || '-1' });

		var species = data?.[0];
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		species = await getSpecies(false, params);
	}

	FormsStore.update(function (store)
	{
		store['Species'] =
		{
			'id': species._id,
			'species[name]': species.name,
			'species[icon]': species.icon
		};
	});

	return species;
}