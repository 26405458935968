import { Link } from 'react-router-dom'

import FooterAddress from './FooterAddress'

export default function FooterContent ()
{
	return (

		<div className="o-container c-footer__main-content">

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Qui sommes-nous
				</h2>

				<p className="c-footer__description">
					Première Formation Française pour les professionnels et référente dans ce domaine, l'OAA a mis toute son expertise dans la réalisation de cette formation à distance.
				</p>
			</div>

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Contactez-nous
				</h2>

				<FooterAddress />
			</div>

		</div>
	);
}