import { useEffect } from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import SuspendedLayout from '../../SuspendedLayout'

import ScrollTopOnRouteChange from '../Navigation/ScrollTopOnRouteChange'
import FrontHeader            from '../Navigation/FrontHeader'
import Footer                 from '../Navigation/Footer'

import request from '../Functions/ajax/request'

import fetchAccessConfig from '../../StoresFunctions/fetchAccessConfig'
import setRolesStore     from '../../StoresFunctions/setRolesStore'

import RolesStore from '../../Stores/Roles'

export default function FrontLayout ()
{
	const { page } = useLoaderData();

	const roles_date = RolesStore.useState(s => s.date);

	useEffect(() =>
	{
		const controller = new AbortController();

		fetchAccessConfig({ controller });

		return () => controller?.abort?.();
	},
	[]);

	useEffect(() =>
	{
		const controller = new AbortController();

		(async function fetchRoles ()
		{
			if (roles_date) return;

			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_date]);

	return (
		<SuspendedLayout resolve={ page }>
			<div className="front-page">
				<FrontHeader />

				<div className="front-page__content">
					<Outlet />
				</div>

				<Footer />
			</div>

			<ScrollTopOnRouteChange />
		</SuspendedLayout>
	);
}