import { defer } from 'react-router-dom'

import pricings_configs from '../Configs/pricings_configs'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function PayOfferLoader ({ params })
{
	return defer({ data: getData(true, params), params });
}

async function getData (retry=true, params)
{
	const matched_code = Object.keys(pricings_configs)
	.find(code => pricings_configs[code].slug === params.code);

	const data = { offer: pricings_configs[matched_code], offers: [] };

	if (!data.offer) throw { throw_up: true, code: 404 }

	try
	{
		const { data: offers } = await request('/offers/get')
		.config({ })
		.post({ });

		const match = offers.find(offer => offer.code === matched_code);

		if (!match)
		{
			throw { ERROR_CODE: 'NOT_FOUND', code: 404 };
		}

		data.offer = { ...data.offer, ...match };

		data.offers = [
			offers.find(o => o.code === 'pdf'),
			offers.find(o => o.code === 'full'),
			offers.find(o => o.code === 'videos')
		];
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		return await getData(false, params);
	}

	return data;
}