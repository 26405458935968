import { defer } from 'react-router-dom'

import request   from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function FrontDiscLoader ({ params })
{
	return defer({ page: getDisciplineData(true, params), params });
}

async function getDisciplineData (retry=true, params)
{
	try
	{
		var { discipline, courses } = await request('/front/disciplines/page')
		.config({ })
		.get(
		{
			slug: params.discipline || '-1',
			from: document.location.pathname
		});
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		discipline = await getDisciplineData(false, params);
	}

	return { discipline, courses: courses || [] };
}