import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { someTime } from '../../../shared/utilities'

export default function AreaLoader ({ params })
{
	return defer({ area: getArea(true, params), params });
}

async function getArea (retry=true, params)
{
	resetForm('Area');

	try
	{
		var { data=[] } = await request('/areas/get')
		.post({ id: params.id || '-1' });

		var area = data?.[0];
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		area = await getArea(false, params);
	}

	FormsStore.update(function (store)
	{
		store['Area'] =
		{
			'id': area._id,
			'area[name]': area.name,
			'area[parent]': area.parent || ''
		};
	});

	return area;
}