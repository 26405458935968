export default function CourseFullIcon (props)
{
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1024 1024" width="1024" height="1024" { ...props }>
			<path fill="currentColor" d="M938.667 0h-853.333c-47.105 0.056-85.277 38.228-85.333 85.333v853.333c0.056 47.105 38.228 85.277 85.333 85.333h853.333c47.105-0.056 85.277-38.228 85.333-85.333v-853.333c-0.056-47.105-38.228-85.277-85.333-85.333zM85.333 34.133h853.333c28.277 0 51.2 22.923 51.2 51.2v51.2h-955.733v-51.2c0-28.277 22.923-51.2 51.2-51.2zM938.667 989.867h-853.333c-28.277 0-51.2-22.923-51.2-51.2v-768h955.733v768c0 28.277-22.923 51.2-51.2 51.2z" />
			<path fill="currentColor" d="M119.467 85.333c0 9.426-7.641 17.067-17.067 17.067s-17.067-7.641-17.067-17.067c0-9.426 7.641-17.067 17.067-17.067s17.067 7.641 17.067 17.067z" />
			<path fill="currentColor" d="M187.733 85.333c0 9.426-7.641 17.067-17.067 17.067s-17.067-7.641-17.067-17.067c0-9.426 7.641-17.067 17.067-17.067s17.067 7.641 17.067 17.067z" />
			<path fill="currentColor" d="M256 85.333c0 9.426-7.641 17.067-17.067 17.067s-17.067-7.641-17.067-17.067c0-9.426 7.641-17.067 17.067-17.067s17.067 7.641 17.067 17.067z" />
			<path fill="currentColor" d="M887.467 102.4h17.067c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067h-17.067c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067z" />
			<path fill="currentColor" d="M716.8 102.4h102.4c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067h-102.4c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067z" />
			<path fill="currentColor" d="M853.333 204.8h-682.667c-28.277 0-51.2 22.923-51.2 51.2v273.067c0 28.277 22.923 51.2 51.2 51.2h682.667c28.277 0 51.2-22.923 51.2-51.2v-273.067c0-28.277-22.923-51.2-51.2-51.2zM870.4 529.067c0 9.426-7.641 17.067-17.067 17.067h-682.667c-9.426 0-17.067-7.641-17.067-17.067v-273.067c0-9.426 7.641-17.067 17.067-17.067h682.667c9.426 0 17.067 7.641 17.067 17.067z" />
			<path fill="currentColor" d="M512 273.067c-65.98 0-119.467 53.487-119.467 119.467s53.487 119.467 119.467 119.467 119.467-53.487 119.467-119.467c-0.075-65.948-53.518-119.391-119.467-119.467zM512 477.867c-47.128 0-85.333-38.205-85.333-85.333s38.205-85.333 85.333-85.333 85.333 38.205 85.333 85.333c-0.056 47.105-38.228 85.277-85.333 85.333z" />
			<path fill="currentColor" d="M555.605 378.334l-51.2-34.133c-7.842-5.231-18.44-3.115-23.671 4.727s-3.115 18.44 4.727 23.671l29.952 19.934-29.901 19.934c-5.073 3.384-7.955 9.217-7.561 15.303s4.005 11.498 9.472 14.199c5.467 2.701 11.96 2.281 17.033-1.103l51.2-34.133c4.747-3.165 7.598-8.494 7.598-14.199s-2.851-11.034-7.598-14.199z" />
			<path fill="currentColor" d="M477.867 648.533h-17.067c0-18.851-15.282-34.133-34.133-34.133h-68.267c-12.603-0.011-24.763 4.646-34.133 13.073-9.371-8.427-21.531-13.084-34.133-13.073h-68.267c-18.851 0-34.133 15.282-34.133 34.133h-17.067c-28.277 0-51.2 22.923-51.2 51.2v204.8c0 28.277 22.923 51.2 51.2 51.2h307.2c28.277 0 51.2-22.923 51.2-51.2v-204.8c0-28.277-22.923-51.2-51.2-51.2zM221.867 819.2h68.267c9.426 0 17.067 7.641 17.067 17.067v17.067h-85.333zM341.333 836.267c0-9.426 7.641-17.067 17.067-17.067h68.267v34.133h-85.333zM358.4 648.533h68.267v136.533h-68.267c-5.827 0.057-11.601 1.119-17.067 3.14v-122.607c0-9.426 7.641-17.067 17.067-17.067zM290.133 648.533c9.426 0 17.067 7.641 17.067 17.067v122.607c-5.466-2.021-11.239-3.084-17.067-3.14h-68.267v-136.533zM494.933 904.533c0 9.426-7.641 17.067-17.067 17.067h-307.2c-9.426 0-17.067-7.641-17.067-17.067v-204.8c0-9.426 7.641-17.067 17.067-17.067h17.067v170.667c0 18.851 15.282 34.133 34.133 34.133h204.8c18.851 0 34.133-15.282 34.133-34.133v-170.667h17.067c9.426 0 17.067 7.641 17.067 17.067z" />
			<path fill="currentColor" d="M887.467 682.667h-273.067c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h273.067c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067z" />
			<path fill="currentColor" d="M768 887.467h-153.6c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h153.6c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067z" />
			<path fill="currentColor" d="M614.4 785.067h153.6c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067h-153.6c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067z" />
			<path fill="currentColor" d="M614.4 853.333h34.133c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067h-34.133c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067z" />
			<path fill="currentColor" d="M887.467 750.933h-51.2c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h51.2c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067z" />
			<path fill="currentColor" d="M887.467 819.2h-170.667c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h170.667c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067z" />
		</svg>
	);
}