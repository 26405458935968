export default function CoursePDFIcon (props)
{
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1024 1024" width="1024" height="1024" { ...props }>
			<path fill="currentColor" d="M160.868 351.119h73.923c10.209 0 18.481-8.274 18.481-18.481v0-43.060c0-0.064 0-0.139 0-0.214 0-23.996-15.244-44.431-36.577-52.149l-0.386-0.122v-32.896l85.75 28.645-29.199 87.783c-0.594 1.735-0.938 3.733-0.938 5.811 0 9.102 6.579 16.666 15.241 18.198l0.111 0.016 221.768 36.961h6.099l221.768-36.961c8.774-1.548 15.353-9.112 15.353-18.214 0-2.078-0.343-4.076-0.976-5.942l0.038 0.131-29.384-87.783 147.846-48.974c7.393-2.531 12.61-9.417 12.61-17.523 0-7.62-4.612-14.163-11.196-16.99l-0.12-0.046-351.133-147.846c-2.126-0.925-4.604-1.463-7.207-1.463s-5.081 0.538-7.329 1.51l0.12-0.046-351.133 147.846c-6.705 2.873-11.316 9.416-11.316 17.036 0 8.106 5.218 14.994 12.48 17.485l0.131 0.039 24.395 8.132v45.093c-21.718 7.841-36.961 28.275-36.961 52.271 0 0.075 0 0.15 0 0.225v-0.012 43.060c0 0.005 0 0.009 0 0.015 0 9.946 7.858 18.058 17.705 18.465l0.037 0.001zM512.001 344.651l-197.374-32.896 28.46-85.566c12.567-10.164 65.606-47.495 168.914-47.495s156.347 36.961 168.914 47.495l28.46 85.566zM512.001 38.61l298.648 125.854-101.274 33.82c-1.061 0.514-1.969 1.067-2.819 1.697l0.047-0.033c-13.861-12.197-73.923-58.029-194.602-58.029s-180.741 45.832-194.602 58.029c-0.794-0.529-1.704-1.023-2.659-1.436l-0.113-0.043-101.274-33.82zM179.349 289.764c-0.045-0.464-0.070-1.004-0.070-1.549 0-9.391 7.612-17.002 17.002-17.002 0.545 0 1.085 0.025 1.617 0.076l-0.068-0.005c0.464-0.045 1.004-0.070 1.549-0.070 9.391 0 17.002 7.612 17.002 17.002 0 0.545-0.025 1.085-0.076 1.617l0.005-0.068v24.395h-36.961z" />
			<path fill="currentColor" d="M898.802 479.56v-24.764c-0.097-26.669-21.737-48.252-48.419-48.252-0.455 0-0.909 0.006-1.361 0.018l0.067-0.001c-121.893 1.477-237.537 26.536-343.126 70.81l6.039-2.247c-99.553-42.027-215.195-67.086-336.514-68.558l-0.574-0.006c-0.561-0.024-1.219-0.037-1.88-0.037-12.969 0-24.714 5.238-33.235 13.715l0.002-0.002c-9.008 8.715-14.6 20.913-14.6 34.417 0 0.050 0 0.1 0 0.15v-0.008 24.764c-32.808 8.453-56.67 37.745-56.737 72.621v396.604c0 41.541 33.675 75.216 75.216 75.216v0h736.64c41.541 0 75.216-33.675 75.216-75.216v0-396.596c-0.066-34.885-23.928-64.177-56.215-72.515l-0.521-0.114zM850.014 443.522c0.050-0.001 0.109-0.001 0.166-0.001 3.174 0 6.051 1.271 8.151 3.33l-0.002-0.002c2.163 2.028 3.511 4.904 3.511 8.097 0 0.013 0 0.025 0 0.038v-0.002 450.559c0 0.002 0 0.003 0 0.006 0 6.096-4.838 11.062-10.885 11.266h-0.018c-115.567 1.775-225.454 23.856-326.978 62.817l6.523-2.199v-469.964c94.472-38.741 204.018-62.052 318.776-63.934l0.755-0.009zM161.977 454.795c0-0.010 0-0.023 0-0.036 0-3.191 1.348-6.067 3.506-8.090l0.006-0.006c2.071-2.056 4.923-3.327 8.073-3.327 0.021 0 0.042 0 0.062 0h-0.003c115.657 1.906 225.327 25.284 325.929 66.302l-6.028-2.174v469.964c-95.046-36.84-204.99-58.986-319.873-60.792l-0.767-0.009c-6.065-0.206-10.904-5.171-10.904-11.267 0-0.002 0-0.005 0-0.007v0zM105.426 948.784v-396.596c0.046-14.268 7.935-26.684 19.58-33.166l0.194-0.099v386.616c0 0.059 0 0.128 0 0.198 0 26.045 20.802 47.232 46.7 47.851l0.058 0.001c83.309 2.031 162.914 14.113 238.833 35.075l-6.9-1.625h-260.208c-21.128 0-38.255-17.127-38.255-38.255v0zM918.576 948.784c0 21.128-17.127 38.255-38.255 38.255h-260.578c69.139-19.371 148.876-31.453 231.117-33.428l1.185-0.022c25.954-0.62 46.756-21.807 46.756-47.852 0-0.069 0-0.139 0-0.208v0.010-386.616c11.839 6.581 19.728 18.997 19.774 33.258v0.007z" />
			<path fill="currentColor" d="M213.168 528.533c18.481 1.294 36.961 2.957 55.442 5.175h3.142c0.331 0.021 0.718 0.033 1.109 0.033 10.224 0 18.514-8.289 18.514-18.514 0-9.835-7.668-17.879-17.352-18.478l-0.053-0.002c-18.481-2.402-38.625-4.066-57.66-5.359-0.359-0.025-0.778-0.039-1.201-0.039-10.228 0-18.52 8.292-18.52 18.52 0 9.805 7.621 17.832 17.262 18.477l0.057 0.003z" />
			<path fill="currentColor" d="M195.981 567.343c-0.023 0.344-0.036 0.746-0.036 1.152 0 9.764 7.572 17.761 17.164 18.434l0.059 0.003c38.881 2.584 74.149 6.855 108.813 12.886l-6.061-0.873h3.142c0.47 0.043 1.018 0.067 1.571 0.067 10.243 0 18.548-8.304 18.548-18.548 0-9.691-7.432-17.646-16.906-18.476l-0.070-0.005c-29.606-5.163-65.989-9.373-102.888-11.677l-3.007-0.15c-0.566-0.061-1.222-0.097-1.889-0.097-9.786 0-17.795 7.605-18.439 17.227l-0.003 0.057z" />
			<path fill="currentColor" d="M195.981 666.584c-0.023 0.344-0.036 0.746-0.036 1.152 0 9.764 7.572 17.761 17.164 18.434l0.059 0.003c80.994 5.21 156.602 19.705 228.516 42.503l-6.748-1.846c0.833 0.133 1.794 0.209 2.772 0.209s1.939-0.076 2.876-0.225l-0.104 0.014c0.833 0.132 1.794 0.207 2.772 0.207 10.32 0 18.687-8.367 18.687-18.687 0-9.342-6.855-17.084-15.81-18.467l-0.105-0.014c-67.622-21.183-146.027-35.64-227.049-40.349l-2.666-0.124c-0.566-0.061-1.222-0.097-1.889-0.097-9.786 0-17.795 7.605-18.439 17.227l-0.003 0.057z" />
			<path fill="currentColor" d="M446.579 760.651c-67.652-21.779-146.153-36.864-227.323-42.167l-2.947-0.154c-0.359-0.025-0.778-0.039-1.201-0.039-10.228 0-18.52 8.292-18.52 18.52 0 9.805 7.621 17.832 17.262 18.477l0.057 0.003c80.996 5.217 156.604 19.711 228.52 42.504l-6.753-1.847c0.833 0.133 1.794 0.209 2.772 0.209s1.939-0.076 2.876-0.225l-0.104 0.014c0.833 0.132 1.794 0.207 2.772 0.207 10.32 0 18.687-8.367 18.687-18.687 0-9.342-6.855-17.084-15.81-18.467l-0.105-0.014z" />
			<path fill="currentColor" d="M446.579 831.617c-67.727-21.506-146.278-36.279-227.477-41.259l-2.793-0.137c-0.359-0.025-0.778-0.039-1.201-0.039-10.228 0-18.52 8.292-18.52 18.52 0 9.805 7.621 17.832 17.262 18.477l0.057 0.003c80.994 5.21 156.602 19.705 228.516 42.503l-6.748-1.846c0.833 0.133 1.794 0.209 2.772 0.209s1.939-0.076 2.876-0.225l-0.104 0.014c0.833 0.132 1.794 0.207 2.772 0.207 10.32 0 18.687-8.367 18.687-18.687 0-9.342-6.855-17.084-15.81-18.467l-0.105-0.014z" />
			<path fill="currentColor" d="M582.782 727.755c0.833 0.133 1.794 0.209 2.772 0.209s1.939-0.076 2.876-0.225l-0.104 0.014c65.166-20.955 140.776-35.449 218.955-40.512l2.814-0.146c9.698-0.649 17.319-8.676 17.319-18.481 0-10.228-8.292-18.52-18.52-18.52-0.423 0-0.842 0.014-1.258 0.042l0.057-0.003c-84.35 5.384-163.114 20.473-238.016 44.239l7.009-1.919c-9.060 1.396-15.915 9.139-15.915 18.481 0 10.32 8.367 18.687 18.687 18.687 0.978 0 1.939-0.075 2.877-0.221l-0.105 0.014z" />
			<path fill="currentColor" d="M582.782 796.688c0.833 0.133 1.794 0.209 2.772 0.209s1.939-0.076 2.876-0.225l-0.104 0.014c65.165-20.949 140.774-35.444 218.951-40.511l2.817-0.147c9.65-0.678 17.223-8.674 17.223-18.438 0-0.405-0.013-0.807-0.039-1.206l0.002 0.054c-0.188-10.060-8.389-18.145-18.477-18.145-0.392 0-0.78 0.013-1.164 0.036l0.053-0.002c-84.353 5.389-163.115 20.479-238.019 44.239l7.010-1.919c-9.060 1.396-15.915 9.139-15.915 18.481 0 10.32 8.367 18.687 18.687 18.687 0.978 0 1.939-0.075 2.877-0.221l-0.105 0.014z" />
			<path fill="currentColor" d="M582.782 868.579c0.833 0.133 1.794 0.209 2.772 0.209s1.939-0.076 2.876-0.225l-0.104 0.014c65.166-20.955 140.776-35.449 218.955-40.512l2.814-0.146c9.698-0.649 17.319-8.676 17.319-18.481 0-10.228-8.292-18.52-18.52-18.52-0.423 0-0.842 0.014-1.258 0.042l0.057-0.003c-83.949 4.83-162.607 19.354-237.464 42.526l7.010-1.869c-9.060 1.396-15.915 9.139-15.915 18.481 0 10.32 8.367 18.687 18.687 18.687 0.978 0 1.939-0.075 2.877-0.221l-0.105 0.014z" />
		</svg>
	);
}