import { shallowEqualObjects, shallowEqualArrays } from 'shallow-equal'

export default function arePropsEqual (prev, current)
{
	const { bemit: prev_bemit, ...prev_props } = prev;
	const { bemit, ...props } = current;

	if (shallowEqualArrays(prev_bemit, bemit) === false) return false;

	return shallowEqualObjects(props, prev_props);
}