import { useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

export default function OnRouteChange ()
{
	const { pathname } = useLocation();

	const _ref = useRef();

	useEffect(function ()
	{
		let reset_scroll = true;

		const prev_path = _ref.current || '/';

		if (pathname.startsWith('/offre-OAA/'))
		{
			reset_scroll = prev_path.startsWith('/offre-OAA/')
			? false
			: true;
		}

		if (reset_scroll)
		{
			window.scrollTo(0, 0);
		}

		_ref.current = pathname;
	},
	[pathname]);

	return null;
}