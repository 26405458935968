export default function CourseVideoIcon (props)
{
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1024 1024" width="1024" height="1024" { ...props }>
			<path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" stroke="currentColor" d="M422.267 100h-322.268c-44.182 0-80 35.818-80 80v664c0 44.182 35.818 80 80 80h824c44.184 0 80-35.818 80-80v-664c0-44.182-35.816-80-80-80h-321.732" />
			<path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" stroke="currentColor" d="M20 684h984" />
			<path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" stroke="currentColor" d="M239.849 804c0 22.092-17.908 40-40 40s-40-17.908-40-40c0-22.092 17.908-40 40-40s40 17.908 40 40z" />
			<path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" stroke="currentColor" d="M365.979 804h498.172" />
			<path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" stroke="currentColor" d="M485.33 299.277l105.040 60.646c24.694 14.256 24.694 49.898 0 64.156l-105.040 60.644c-24.694 14.258-55.56-3.564-55.56-32.078v-121.29c0-28.514 30.866-46.336 55.56-32.078z" />
			<path fill="none" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" stroke="currentColor" d="M512.254 100v0" />
		</svg>
	);
}