import { useLocation } from 'react-router-dom'

import FooterContent from './FooterContent'
import FooterLegal   from './FooterLegal'
import FooterTop     from './FooterTop'

export default function Footer ()
{
	// const { pathname } = useLocation();

	// if (pathname?.startsWith?.('/offre-OAA/')) return null;

	return (
		<footer className="c-footer">

			<div className="c-footer__top">
				<FooterTop />
			</div>

			<div className="c-footer__main">
				<FooterContent />
			</div>

			<div className="c-footer__legal">
				<FooterLegal />
			</div>

		</footer>
	);
}