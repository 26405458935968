import UserPanelStore from '../Stores/UserPanelStore'

export function closeUserPanel ()
{
	UserPanelStore.update(s => ({ open: false }));
}

export function openUserPanel (params={ })
{
	UserPanelStore.update(s => ({ open: true, ...params }));
}