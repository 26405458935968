import { DateTime } from 'luxon'

import RolesStore from '../Stores/Roles'

export default function setRolesStore ({ roles=[], user_id='' })
{
	const status = Array.isArray(roles) && roles.length > 0
	? 'authenticated'
	: 'guest';

	RolesStore.update(function (store)
	{
		store.roles = Array.isArray(roles) ? roles : [];
		store.date = DateTime.now().toISO();
		store.status = status;
		store.user_id = user_id;

		store.home = store.roles.includes('admin')
		? '/admin/accueil'
		: store.roles.includes('editor')
		? '/gestion/formations'
		: store.roles.includes('oaa')
		? '/gestion/liste-des-commandes'
		: '/';

		window.localStorage.setItem('roles', JSON.stringify(store.roles));
		window.localStorage.setItem('roles@date', store.date);
		window.localStorage.setItem('roles@status', store.status);
		window.localStorage.setItem('roles@home', store.home);
		window.localStorage.setItem('roles@user_id', store.user_id);
	});
}