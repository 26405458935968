import React, { PureComponent } from 'react'

import config from '../../shared_config'

const types =
{
	default: config.logo,
	white: config.white_logo
};

export default class Logo extends PureComponent
{
	render ()
	{
		const props = this.props;

		let output =
		(
			<img src={ types[props.type || 'default'] }
				alt={ 'Logo ' + config.site_name }
				style={ props.style }
				className={ props.className }
				width={ props.width } height={ props.height }
			/>
		);

		return output;
	}
}
