import axios from 'axios'

import timeoutPromise from './timeoutPromise'
import getTimeout     from './getTimeout'

const DEFAULT_ERR = 'Une erreur inattendue est survenue.';

const HTTP_METHODS =
[
	'GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'CONNECT', 'OPTIONS', 'TRACE', 'PATCH'
]
.map(name => name.toLocaleLowerCase());

const ERRORS =
{
	'DENIED':
	{
		'default': 'Votre connexion a expirée, connectez-vous pour accéder à cette ressource.',

		'action': 'Votre connexion a expirée, connectez-vous pour pouvoir faire cette action.'
	},

	'FORBIDDEN':
	{
		'default': 'Votre n\'avez pas les droits nécessaires pour accéder à cette ressource.',

		'action': 'Votre n\'avez pas les droits nécessaires pour effectuer à cette action.'
	},

	'NOT_FOUND':
	{
		'default': 'Ressource introuvable.',

		'action': 'Données demandées introuvables.'
	}
};

/**
 * @typedef { Object<String, any> } RequestConfig
 *
 * @property { Object } config request config
 * @property { any } [config.navigation] react navigation prop
 * @property { AbortController } [config.controller] AbortController instance
 * @property { Boolean } [config.redirect=true] auto redirect on auth fail
 * @property { Boolean } [config.auth=true] add auth token in request
 * @property { Number } [config.min_req_time=600] minimum request duration (ms)
**/

/**
 * @description construct a request with its url
 *
 * @param { String } url req url (starting with / or uri with protocol like https:)
 *
 * @returns { Object<String, function> } chainable object with method (.post, etc)
**/
export default function request (url)
{
	const chainable =
	{
		config: function callConfiguration (props)
		{
			return configure(url, props);
		}
	};

	HTTP_METHODS.forEach(function (method)
	{
	/**
	 * @param { Object.<string, any> } body
	 *
	 * @returns { Promise<any> } .post, .get, etc with params
	**/
		chainable[method] = function callRequest (body)
		{
			return configure(url, { })[method](body);
		};
	});

	return chainable;
}

/**
 * @description configure the request
 *
 * @param { String } url passed url param
 * @param { RequestConfig } config
 *
 * @return { Object<String, function> } chainable object with method (.post, etc)
**/
function configure (url, config)
{
/** @type Object **/
	const chainable = { };

	HTTP_METHODS.forEach(function (method)
	{
	/**
	 * @param { Object<String, any> } body
	 *
	 * @returns { Promise<Object> } .post, .get, etc with params
	**/
		chainable[method] = function callRequest (body)
		{
			return makeRequest(url, method, config, body);
		};
	});

	return chainable;
}

/**
 * @param { String } url req url starting with /
 * @param { String } method HTTP method
 * @param { RequestConfig } config request configuration
 * @param { Object } params={ } request params
 *
 * @returns { Promise<Object> } reponse object
**/
async function makeRequest (url, method, config, params)
{
	const { redirect=false, auth=true, min_req_time=400 } = config;

	let req_time = new Date();

/** @type { import('axios').AxiosRequestHeaders } **/
	const headers = { };

	let completed = false;

	try
	{
		var controller = config.controller || new AbortController();

		timeoutPromise(config.timeout).then(function ()
		{
			if (completed === false) controller.abort();
		});

		const { data: response } = await axios(
		{
			method: method || 'get',
			url: url,

			[['post', 'put'].includes(method) ? 'data' : 'params']: params,

			headers: headers,
			signal: controller.signal,
			onUploadProgress: config.onUploadProgress,
			timeout: config.timeout || 1000 * 40 // 40s
		});

		completed = true;

		if (controller?.signal?.aborted) throw 'ABORTED';
		await timeoutPromise(getTimeout(req_time, min_req_time));
		if (controller?.signal?.aborted) throw 'ABORTED';
		return response;
	}
	catch (e)
	{
		completed = true;

		if (controller?.signal?.aborted) throw 'ABORTED';
		await timeoutPromise(getTimeout(req_time, min_req_time));
		if (controller?.signal?.aborted) throw 'ABORTED';

		const response = e.response?.data;

		const status_code = e?.response?.status || 500;

		const update =
		{
			error: response?.error || DEFAULT_ERR,
			warning: response?.warning,
			fields: response?.fields
		};

		if (status_code === 404) update.ERROR_CODE = 'NOT_FOUND';

		if (!response || typeof response === 'string')
		{
			if (status_code === 404)
			{
				const error_msgs = ERRORS[update.ERROR_CODE] || { };

				update.error = error_msgs[config.keyword || 'default'] || DEFAULT_ERR;

				throw update;
			}

			throw { error: DEFAULT_ERR };
		}

		const not_auth = status_code === 401 && response?.error === 'Unauthorized';
		const forbidden = status_code === 403 && response?.error === 'Forbidden';

		if (not_auth || forbidden)
		{
			if (redirect)
			{
				return document.location.href = '/login';
			}

			update.ERROR_CODE = not_auth ? 'DENIED' : 'FORBIDDEN';

			const error_msgs = ERRORS[update.ERROR_CODE] || { };

			update.error = error_msgs[config.keyword || 'default'] || DEFAULT_ERR;
		}

		throw update;
	}
}